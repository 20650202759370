
.company{
    box-shadow: 0px 0px 4px 0px #00000040;
}
.company__img{
    height: 285px;
    width: 100%;
    border-radius:10px
}
.company__content-cont{
    padding: 17.5px 16px;
}
body[dir='rtl'] .company__content-cont{
    text-align: end;
}
.company__content-name{
    color: #263238;
    font-size: 24px;
}
.company__content-name:hover{
    color: #263238
}
.company__category-cont{
    color:#263238;
    margin-bottom:15px;
    margin-top: 12px;
}
.company__category-title{
    font-weight: 500;
    margin-right: 16px;
    font-family: 'RedexMedium', sans-serif!important;
}
body[dir='rtl'] .company__category-title{
    margin-right: 0;
    margin-left: 16px;

}
.company__category-name{
    font-family: 'RedexLight', sans-serif!important;
}