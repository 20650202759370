#add-company{
    padding: 120px 0;
}
.add-company__cont{
    position: relative;
    padding: 60px 40px;
    border-radius: 10px;
    text-align: start;
}
.add-company__cont::before{
    content:'';
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: url('/src/assets/imgs/addCompanyBG.png') no-repeat right ,#C4E85F;
    border-radius: 10px;
    z-index: -1;
}
body[dir='rtl'] .add-company__cont::before{
    transform: rotateY(180deg);
}
.add-company__desc{
    color: #325465;
    margin-bottom: 10px;
    font-size: 24px;
}
.add-company__title{
    font-size: 36px;
    color: #325465;
    font-weight: bold;
    margin-bottom: 24px;
    font-family: 'RedexBold', sans-serif!important;

}
.add-company__button{
    background: white;
    padding: 12px 24px;
    margin: auto;
    border-radius: 10px;
    color: #263238;
    text-decoration: none;
    margin-top: 26px;
    transition: var(--transition);
}
.add-company__button:hover{
    text-decoration: none;
    color:inherit;
    opacity: .9
}
@media (max-width:426px){
    .add-company__cont::before{
        background: #C4E85F;
    }
    #add-company{
        padding: 70px 0;
    }
    .add-company__cont{
        padding: 50px 30px;
    }
}