#cat-header{
    padding: 60px;
    background-color: var(--main-color);
    margin-bottom: 60px;
    position: relative;
    overflow: hidden;
}
.cat-header__heading{
    font-size: 36px;
    font-family: 'RedexBold' , sans-serif;
    color: var(--main-text-color);
    text-align: center;
    position: relative;
}

.comming-soon__animation{
    position: absolute;
    background: url('/src/assets/icons/bgAnimation.svg') no-repeat;
    width: 110px;
    height: 44px;
    transition:var(--transition);
}
.comming-soon__animation:nth-of-type(1){
    top: 10%;
    left: 0;
    animation: moveWave 80s linear  infinite alternate;

}
.comming-soon__animation:nth-of-type(2){
    top: 15%;
    left: 15%;
    animation: moveWave 40s linear  infinite alternate;

}
.comming-soon__animation:nth-of-type(3){
    top: 70%;
    left: 40%;
    animation: moveWave 22s linear  infinite alternate;

}
.comming-soon__animation:nth-of-type(4){
    top: 50%;
    left: 80%;
    animation: moveWave 60s linear  infinite alternate;

}
.comming-soon__animation:nth-of-type(5){
    top: 33%;
    left: 10%;
    animation: moveWave 30s linear  infinite alternate;

}
.comming-soon__animation:nth-of-type(6){
    top: 44%;
    left: 85%;
    animation: moveWave 44s linear  infinite alternate;

}
@keyframes moveWave {
    10%{
        left:20%
    }
    20%{
        left:40%
    }
    30%{
        left:60%
    }
    40%{
        left:80%
    }
    50%{
        left:90%
    }
    60%{
        left:80%
    }
    70%{
        left:60%
    }
    80%{
        left:40%
    }
    90%{
        left:20%
    }
    100%{
        left:10%
    }
}
@media (max-width:426px){
    #cat-header{
        padding: 30px;
        margin-bottom: 30px;
    }
    .cat-header__heading{
        font-size: 28px;

    }
}