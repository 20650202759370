.slider{
    height: 90vh;
    background: url('/src/assets/imgs/slider.jpg') no-repeat;
    background-size: cover;
}
.slider__content-cont{
    margin: auto 0 130px;
    background-color: var(--main-text-color);
    padding: 45px 70px 30px;
    color: white;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}
.slider__content-heading{
    font-weight: bold;
    font-size: 54px;
    margin-bottom: 12px;
}
.slider__content-desc{
    margin-bottom: 20px;
}
.slider__search-form{
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 50px;
    border-radius: 8px;
    margin-right: 24px;
    margin-inline-end: 24px;
    overflow: hidden;
}
.slider__search-select{
    height: 100%;
    width: 220px;
    padding: 0 12px;
    outline: 0;
    border: 0;
    border-right: 1px solid #D9D9D9;
    border-inline-end: 1px solid #D9D9D9;
}
.slider__search-input{
    height: 100%;
    width: 310px;
    padding: 0 12px;
    outline: 0;
    flex-grow: 1;
    outline: 0;
    border: 0;
    border-right: 1px solid #D9D9D9;
    border-inline-end: 1px solid #D9D9D9;
}
.slider__search-submit{
    background-color: #EDEDED;
    border: 0;
    outline: 0;
    position: absolute;
    right: 0;
    height: 96%;
    width: 50px;
    border-radius: 10px;
}
body[dir='rtl'] .slider__search-submit{
    left: 0;
    right: initial;
}
.slider__search-icon{
    width: 24px;
    height: 24px;
}
@media(max-width:769px){
    .slider__content-heading{
        font-size: 40px;
    }
    .slider__search-select{
        width: 150px
    }
}
@media(max-width:426px){
    .slider__content-cont{
        padding: 45px 15px 30px;
    }
    .slider__search-form{
        margin-right: 0px;
        margin-inline-end: 0px;
    }
}
@media(max-width:321px){
    .slider__search-input{
        width: 200px;
    }
}