.preview-company__login-with-social{
    display: flex;
    margin-bottom: 20px;
    margin-top: 30px;
    background-color:white;

}


.preview-company__login-with-social-cont{
    padding:14px 20px;
    background-color:#E2EFCE;
    border-radius: 10px;
    flex-grow: 1;
    margin-right: 20px;
    margin-inline-end: 20px;
}
.preview-company__login-with-social-cont p{
    margin:0
}
.preview-company__login-with-social-icons-cont{
    display:flex;
}
.preview-company__login-with-social-icon{
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 4px 0px #00000040;
    display: grid;
    place-items: center;
    border-radius: 10px;
    margin-right: 10px;
    margin-inline-end: 10px;
    background-color: white;
    border: 0;
    outline: 0;
}
.preview-company__login-with-social-icon:last-of-type{
    margin-right: 0px;
    margin-inline-end: 0px;

}
@media (max-width: 768px){
    .preview-company__login-with-social{
        flex-direction: column;
    }
    .preview-company__login-with-social-cont{
        margin-right: 0;
    }
    .preview-company__login-with-social-icons-cont{
        margin: auto;
        margin-top: 20px;
    }
}