
/***************side-navbar*******************/
.side-navbar{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    width: 0%;
    transition: var(--transition);
    overflow: hidden;
    height: 0%;
    border-radius: 50%;
    border-top-left-radius: 0;
    z-index: 10000;
    padding: 0;
}
.side-navbar--open{
    height: 100%;
    width: 100%;
    border-radius: 0;
}
.side-navbar__content{
    width: 70%;
    background-color: white;
    height: 100%;
    padding: 60px 0px;
    overflow-y: auto;
    text-align: start;
    font-weight: bold;
}
.side-navbar__profile-cont{
    padding: 0px 30px;
    border-bottom: 1px solid #0000001F;
}
.side-navbar__overlayer{
    width: 30%;
    background-color: black;
    height: 100%;
    opacity: .8;
}
.side-navbar__nav-list{
    list-style: none;
    margin: 0;
    padding: 0
}
.side-navbar__profile-img{
    width: 70px;
    height: 70px;
    margin-bottom: 15px;
    border: 1px solid black;
    border-radius: 50%;
    padding: 6px;
    object-fit: contain;
}
.side-navbar__profile-name{
    color: black;
    font-family: 'PoppinsMedium';
}
.side-navbar__nav-item{
    padding: 20px 40px;
    transition:var(--transition);
}
.side-navbar__nav-link{
    transition:var(--transition)
}
.side-navbar__nav-item:hover .side-navbar__nav-link{
    color: var(--main-color);
    text-decoration: none;
}
.side-navbar__nav-item.active{
    background: var(--main-color);
    color: white;
}
.side-navbar__nav-item.active .side-navbar__nav-link{
    color: var(--second-text-color);
}
.side-navbar__nav-list .side-navbar__nav-item:last-of-type{
    border-bottom: 1px solid #0000001F;
}
.side-navbar__nav-link{
    color:black;
    transition: var(--transition);
}
.side-navbar__logOut-cont{
    padding:30px;
}
.side-navbar__logOut{
    background-color: transparent;
    border: 0;
    color: black;
    margin: auto;
    cursor: pointer;
}
.side-navbar__logOut-icon{
    margin-inline-end: 5px;
}
.side-navbar__content::-webkit-scrollbar{
    width: 8px;
}
.side-navbar__content::-webkit-scrollbar-thumb{
    background: var(--main-color);
}
.navbar__search-form{
    height: 50px;
    position: relative;
    width: 90%;
    margin: auto;
    margin-bottom:40px
}
.navbar__search-select{
    height: 100%;
    width: 20%;
    padding: 0 12px;
    outline: 0;
    border: 1px solid #D9D9D9;
    border-inline-end: 1px solid #D9D9D9;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}
body[dir='rtl'] .navbar__search-select{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
.navbar__search-input{
    height: 100%;
    width: 80%;
    padding: 0 12px;
    outline: 0;
    border: 1px solid #D9D9D9;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}
body[dir='rtl'] .navbar__search-input{
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.navbar__search-submit{
    background-color: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    right: 12px;
    transform: translateY(-50%);
    top: 50%;
}
body[dir='rtl'] .navbar__search-submit{
    left: 12px;
    right: initial;
}

.navbar__search-icon{
    width: 16px;
    height: 16px;
}
.navbar__search-icon path{
    fill:#663399;
}
/* .main-navbar__toggle-button{
    background: transparent!important;
    box-shadow: none!important;
} */
.navbar__change-langs{
    background-color: #343a40;
    border: 0;
    color: white;
    width: 90%;
    height: 50px;
    margin: 30px auto 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
}
.navbar__account{
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: black;
    border-radius: 25px;
    height: 50px;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    margin-top: 20px;
}
.navbar__change-langs{
    margin: 30px auto 20px;
    width: 90%;
    border: 0;
}
@media(min-width:1200px){
    .side-navbar{
        width:0!important;
    }
}
@media(max-width:426px){
    .side-navbar__nav-item{
        padding: 20px 30px;
    }
}