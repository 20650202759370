.categories__filter-cont{
    margin-bottom: 32px;
}
.categories__filter-list{
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    align-items: center;
    justify-content: center;
}
body[dir='ltr'] .categories__filter-item:first-of-type .categories__filter-link{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
body[dir='rtl'] .categories__filter-item:first-of-type .categories__filter-link{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
body[dir='ltr'] .categories__filter-item:last-of-type .categories__filter-link{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
body[dir='rtl']  .categories__filter-item:last-of-type .categories__filter-link{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.categories__filter-link{
    padding: 14px 24px;
    border: 1px solid #D9D9D9;
    background: transparent;
    color: #263238;
}
.categories__filter-link.active{
    background-color: var(--main-text-color);
    color: #C4E85F;
}

@media (max-width:769px){
    .categories__filter-link{
        width: 100%;
        border-radius: 10px !important;
        margin-bottom: 10px;
    }
    .categories__filter-list{
        display: grid;
        align-items: initial;
        justify-content: initial;
    }
}