footer{
    padding: 60px 0 20px;
    background-color: #161E22;
    color: white;
}
.footer__top{
    display: flex;
    margin-bottom:50px
}
.footer__list-cont{
    display: flex;
}
.footer__list{
    margin-right: 50px;
    margin-inline-end: 50px;
}
.footer__item{
    margin-bottom: 10px;
}
.footer__link{
    color: white;
    font-weight: bold;
    font-family: 'RedexMedium', sans-serif!important;
}
.footer__link:hover{
    color: white;
    text-decoration: none;
}
.footer__info{
    margin-left: auto;
    margin-inline-start: auto;
}
.footer__info-logo{
    margin-bottom: 10px;
}

.footer__bottom{
    display: flex;
    border-top: 1px solid #173341;
    font-size: 14px;
    padding-top: 20px;
}

.footer__develop{
    /* margin-left: auto; */
    margin-inline-start: auto;
}
.footer__info-desc{
    font-family: 'RedexLight', sans-serif!important;
}

@media (max-width:426px){
    .footer__list-cont{
        order: 2;
        justify-content: center;
    }
    .footer__top{
        flex-direction: column;
        margin-bottom: 20px;
    }
    .footer__list{
        order: 2;
        margin-right: 20px;
        margin-inline-end: 20px;
    }
    
    .footer__list:last-of-type{
        margin-right: 0px;
        margin-inline-end: 0px;
    }
    .footer__info{
        order: 1;
        margin: auto;
        margin-bottom: 20px;
    }
    .footer__bottom{
        flex-direction: column;
        text-align: center;
    }
    .footer__develop{
        margin-left: initial;
        margin-inline-start: initial;
        margin-top: 10px;
    }
}