.add-company__contact{
    position:relative;
    padding: 40px 30px;
    /* box-shadow: 0px 0px 4px 0px #00000040; */
    border-radius: 10px;
    visibility:visible;
    transition: var(--transition);
}
.add-company__hidden{
    position:absolute;
    transform: translateX(400%);
    visibility: hidden;
    transition: var(--transition);
}
.add-company__contact-cont{
    padding: 0 40px;
}
.add-company__contact-field{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.add-company__field-label{
    font-family: 'RedexMedium';
}
.add-company__field-input{
    height: 50px;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    outline:0;
    transition: var(--transition)
}
.add-company__field-input:focus-visible{
    border:1px solid #797070;
}
.add-company__contact-field{

}
.add-company__field-upload-cont{

}

.add-company__contact-title{
    font-size: 24px;
    margin-bottom: 50px;
    color:var(--main-text-color);
}
.add-company__field-file{
    position: relative;
    border-radius: 10px;
    width: 180px;
    height: 180px;
    background-color: #F2F2F2;
    display: grid;
    place-content: center;
    cursor: pointer;
    transition:var(--transition);
    overflow: hidden;
    
}
.add-company__field-file::before{
    content: '';
    position: absolute;
    top: -40px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 150%;
    width: 160%;
    background-color: white;
    opacity: 0.3;
    transform: translate(-120% ,0%) rotate(136deg);
    transition: var(--transition);
}
.add-company__field-file:hover::before{
    transform:translate(100%,-90px) rotate(-45deg);
}
body[dir='rtl'] .add-company__field-file::before{
    transform:translate(120%,-90px) rotate(45deg);
}
body[dir='rtl'] .add-company__field-file:hover::before{
    transform:translate(-100%,-90px) rotate(45deg);
}

.add-company__move-stage{
    position: relative;
    padding: 14px 20px;
    color: var(--main-text-color);
    font-family: 'RedexMedium';
    border-radius: 10px;
    border: 0;
    outline: 0;
    transition: var(--transition);
    overflow: hidden;
    background-color: var(--main-color);
}
.add-company__move-stage span{
    position: relative;
}
.add-company__move-stage:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.3;
    transform: translateX(-100%);
    transition: var(--transition);
}
.add-company__move-stage:hover::before{
    transform: translateX(0%);
}
body[dir='rtl'] .add-company__move-stage:hover::before{
    transform: translateX(0%);
}
body[dir='rtl'] .add-company__move-stage::before{
    transform: translateX(100%);
}
.add-company__move-stage--next{
    margin-inline-start: auto;
}
.add-company__error-message{
    color: red;
}
.add-company__required{
    color: red;
    margin-inline-start: 5px;
    font-size: 20px;
}
@media (max-width:769px){
    .add-company__contact{
        padding: 40px 10px;
    }
    .add-company__contact-cont{
        padding: 0px 20px;
    }
    .add-company__field-file{
        width: 140px;
        height: 140px;
    }
}   
@media (max-width:426px){
    .add-company__contact{
        padding: 20px 10px;
    }
    .add-company__contact-cont{
        padding: 0px;
    }
    .add-company__field-file{
        width: 120px;
        height: 120px;
    }
    .add-company__contact-field{
        margin-bottom: 20px;
    }
    .add-company__contact-title{
        margin-bottom: 30px;
    }
}