:root{
  --main-color:#C4E85F;
  --main-text-color:#263238;
  --transition: all .3s ease-in-out;
}
@font-face {
  font-family: 'RedexRegular';
  src: url('./assets/fonts/ReadexPro-Regular.ttf');
}
@font-face {
  font-family: 'RedexBold';
  src: url('./assets/fonts/ReadexPro-Bold.ttf');
}
@font-face {
  font-family: 'RedexSemiBold';
  src: url('./assets/fonts/ReadexPro-SemiBold.ttf');
}
@font-face {
  font-family: 'RedexLight';
  src: url('./assets/fonts/ReadexPro-Light.ttf');
}
@font-face {
  font-family: 'RedexMedium';
  src: url('./assets/fonts/ReadexPro-Medium.ttf');
}
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'RedexRegular', sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background-color: #F9FBF6!important; */
}
body[dir='rtl']{
  text-align: right
}
ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
.swiper{
  direction: ltr;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  padding: 0 12px;
}
input{
  padding: 0 12px;
}
body[dir='rtl'] select {
  background-position-x: 0%;
}
p{
  margin: 0;
}
.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E") !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-next:after{
  content: none !important;
}
.swiper-button-prev:after, .swiper-rtl .swiper-button-prev:after{
  content: none !important;
}