

.navbar{
    padding: 30px 20px;
    background-color: var(--main-text-color);
}
.navbar__toggler-button{
    color: white;
    border: 0;
}
.navbar__toggler-icon{
    width: 30px;
    color: white;
}
.main-navbar__toggle-icon{
    color:var(--main-color);
    width:30px;
}
.navbar__search-form{
    height: 42px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 24px;
    margin-inline-end: 24px;
}
.navbar__search-select{
    height: 100%;
    width: 120px;
    padding: 0 12px;
    outline: 0;
    border: 0;
    border-right: 1px solid #D9D9D9;
    border-inline-end: 1px solid #D9D9D9;
}
.navbar__search-input{
    height: 100%;
    width: 310px;
    padding: 0 12px;
    outline: 0;
    outline: 0;
    border: 0;
}
.navbar__search-submit{
    background-color: transparent;
    border: 0;
    outline: 0;
    position: absolute;
    right: 12px;
    transform: translateY(-50%);
    top: 50%;
}
body[dir='rtl'] .navbar__search-submit{
    left: 12px;
    right: initial;
}
.navbar__search-icon{
    width: 16px;
    height: 16px;
}
.navbar__search-icon path{
    fill:#663399;
}
.navbar__link{
    color: white!important;
    margin-right: 24px;
    margin-inline-end: 24px
}
.navbar__change-langs{
    margin-left: auto;
    margin-inline-start: auto;
    margin-right: 24px;
    margin-inline-end: 24px;
    background-color: transparent;
    border: 2px solid var(--main-color);
    color: white;
    border-radius: 10px;
    width:80px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navbar__account{
    /* margin-right: 24px; */
    /* margin-inline-end: 24px; */
    background-color: var(--main-color);
    border-color: var(--main-color);
    color: black;
    border-radius: 10px;
    width:120px;
    height: 42px;
}
.main-navbar__toggle-button{
    border:0;
    display:none;
    background-color:transparent
}
.main-navbar__toggle-button:focus ,.main-navbar__toggle-button:hover ,.main-navbar__toggle-button:active{
    background-color:transparent;
    box-shadow: none;
}
/* @media(max-width:1200px){ */
@media(max-width:576px){

    .main-navbar__toggle-button{
        display: flex!important;
    }
    .navbar__change-langs{
        background-color: var(--main-text-color);
        border-color: var(--main-text-color);
        color: white;
        border-radius: 25px;
        height: 50px;
        width: 90%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        margin-top: 20px;
    }
}

@media (max-width:426px){
    .side-navbar__overlayer{
        width: 10%;
    }
    .side-navbar__content{
        width: 90%;
    }
}



