.add-company-stages{
    margin-bottom: 40px;
}
.add-company-stages__cont{
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    text-align: center;
}
.add-company__stage{
    position: relative;
    border: 1px solid var(--main-color);
    padding: 14px 20px;
    border-radius: 50px;
    width: 80%;
    margin: auto;
    color: var(--main-text-color);
    background-color: white;
    transition:var(--transition);
}
.add-company__stage::after{
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    left: 0;
    top: 0%;
    background-color: var(--main-color);
    transition:var(--transition);
    border-radius: 50px;
}
body[dir='rtl'] .add-company__stage::after{
    left: initial;
    right:0;
}
.add-company__stage.add-company__stage--completed::after{
    width: 100%;
}
.add-company__stage span{
    position: relative;
    z-index: 100;
}
.add-company__stage::before{
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    right: 0;
    top: 50%;
    background: var(--main-color);
    transform: translateX(56px);
    z-index: -1;
    transition:var(--transition);
}
body[dir='ltr'] .add-company__stage:last-of-type:before{
    width: 0;
}
body[dir='rtl'] .add-company__stage:first-of-type:before{
    width: 0;
}
@media (max-width:1025px){
    .add-company-stages__cont{
        grid-template-columns: repeat(2 , 1fr);
    }
    .add-company__stage{
        margin-bottom: 20px;
    }
    .add-company__stage:before{
        display:none
    }
}
@media (max-width:426px){
    .add-company__stage{
        font-size: 14px;
    }
    .add-company-stages{
        margin-bottom: 30px;
    }
}