#contact-us {
  margin-top: 120px;
  background: url("/src/assets/imgs/aboutUsBG.png") no-repeat left;
}
.contact-us__conetnt{
  position:relative;
  padding: 40px 30px;
  /* box-shadow: 0px 0px 4px 0px #00000040; */
  border-radius: 10px;
  visibility:visible;
  transition: var(--transition);
}
.contact-us__conetnt-desc{
  line-height: 26px;
  color: #000001;
  margin-bottom: 30px;
}
.contact-us__conetnt-title{
  color: #325465;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 16px;
  font-family: 'RedexBold', sans-serif!important;
}
.contact-us__required {
  color: red;
  margin-inline-start: 5px;
  font-size: 20px;
}
.contact-us__field {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.contact-us__field-label {
  font-family: "RedexMedium";
}
.contact-us__field-input {
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  outline: 0;
  transition: var(--transition);
  width: 50%;
}
.contact-us__field-input:focus-visible {
  border: 1px solid #797070;
}
.contact-us__field-label {
  font-family: "RedexMedium";
}

.contact-us__move-stage {
  position: relative;
  padding: 14px 20px;
  color: var(--main-text-color);
  font-family: "RedexMedium";
  border-radius: 10px;
  border: 0;
  outline: 0;
  transition: var(--transition);
  overflow: hidden;
  background-color: var(--main-color);
}
.contact-us__move-stage span {
  position: relative;
}
.contact-us__move-stage:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.3;
  transform: translateX(-100%);
  transition: var(--transition);
}
.contact-us__stage-cont{
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 10px;
    margin-bottom: 100px;
    padding: 25px;
}
.contact-us__move-stage:hover::before {
  transform: translateX(0%);
}
body[dir="rtl"] .contact-us__move-stage:hover::before {
  transform: translateX(0%);
}
body[dir="rtl"] .contact-us__move-stage::before {
  transform: translateX(100%);
}
.contact-us__move-stage--submit {
  margin-inline-start: auto;
}
.contact-us__error-message {
  color: red;
  position: relative;
}
.contact-us__field-input--textarea{
    height: 200px;
    padding: 20px 12px;
}
.contact-us__btn{
  padding: 8px 50px;
  margin: auto;
}
.contact-us_div_btn{
  /* background-color: #073f5b; */
  /* padding: 90px; */
  /* height: 50px; */
  display: flex;
  justify-content: center;
  width: 100%;
}