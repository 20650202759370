.category{
    display: flex;
    margin-bottom: 24px;
    box-shadow: 0px 0px 4px 0px #00000040;
    border-radius: 10px;
    overflow: hidden;
}
.category__img-cont{

}
.category__img{
    width: 180px;
    height: 180px;
    border-radius: 10px;
}
.category__content{
    padding: 18px 24px;
    display: flex;
    flex-direction:column ;
}
.category__content-name{
    color: #263238;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 10px;
}
.category__content-name:hover{
    color: #263238;
    
}
.category__content-cat{
    color: #263238;
}
.category__content__rate-cont{
    margin-top: auto;
}
.category__cat-name{
    font-weight: 500;
    margin-inline-end: 20px;
    font-family: 'RedexMedium', sans-serif!important;
}
.category__cat-desc{
    font-family: 'RedexLight', sans-serif!important;
}
@media (max-width:426px){
    .category{
        flex-direction:column;
    }
    .category__img{
        width: 100%;
        height: initial;
    }
    .category__content-cat{
        margin-bottom:30px
    }
}