.preview-company__company-img-cont{
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-bottom:30px;
    border-radius: 10px;
    background-color:white;

}
.preview-company__company-img{
    border-radius: 10px;
    width: 100%;
    height:440px
}
.preview-company__company-details{
    padding: 20px 36px;

}
.preview-company__company-details{
    display: flex;
    height: 165px;
    /* border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; */
}
.preview-company__company-logo{
    transform: translateY(-30%);
    margin-right: 20px;
    border-radius: 10px;
    height: 180px;
}
.preview-company__name{
    font-family: 'RedexBold';
    font-size:36px;
    color:#325465;
    margin-bottom: 22px;
}
.preview-company__cat-name{
    color: var(--main-text-color);
    margin-right: 16px;
    font-size:16px;
    font-family: "RedexMedium";
}
.preview-company-cat-desc{
    color: var(--main-text-color);
    font-size:16px;
    font-family: "RedexLight";
}
.preview-company__cat{
    margin-bottom: 16px;
}
@media (max-width:769px) {
    .preview-company__company-logo{
        display: none;
    }
}
@media (max-width:426px) {
    
    .preview-company__company-img{
        height: 300px
    }
    .preview-company__company-details{
        height: 155px;
        padding: 10px 26px;
    }
    .preview-company__name{
        margin-bottom: 12px;
        font-size: 30px;
    }
    .preview-company__cat{
        margin-bottom: 10px;
    }
    
}