.comming-soon {
  background: url("/src/assets/imgs/commingSoonBg.png") bottom left repeat-x,
    var(--main-color);
  background-size: 50% 60%;
  min-height: 100vh;
  text-align: center;
  padding-top: 70px;
  position: relative;
  overflow: hidden;
}
.comming-soon__logo {
  margin: 0 auto;
  position: relative;
}
.comming-soon__content-cont {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.comming-soon__content {
  margin-top: 50px;
  position: relative;
}
.comming-soon__sub-title {
  font-size: 46px;
  font-family: "RedexMedium";
  letter-spacing: 10px;
  color: var(--main-text-color);
}
body[dir="rtl"] .comming-soon__sub-title {
  letter-spacing: 0 !important;
}
.comming-soon__title {
  font-size: 56px;
  font-family: "RedexBold";
  letter-spacing: 10px;
  margin-bottom: 30px;
  color: var(--main-text-color);
}
body[dir="rtl"] .comming-soon__title {
  letter-spacing: 0 !important;
}
.comming-soon__desc {
  font-size: 20px;
  width: 60%;
  margin: 0 auto 80px;
  color: var(--main-text-color);
}
.comming-soon__add-button {
  text-decoration: none;
  background-color: white;
  padding: 20px 14px;
  border-radius: 10px;
  color: var(--main-text-color);
}
.comming-soon__add-button:hover {
  text-decoration: none;
  color: var(--main-text-color);
}

.comming-soon__animation {
  position: absolute;
  background: url("/src/assets/icons/bgAnimation.svg") no-repeat;
  width: 110px;
  height: 44px;
  transition: var(--transition);
}
.comming-soon__animation:nth-of-type(1) {
  top: 10%;
  left: 0;
  animation: moveWave 80s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(2) {
  top: 15%;
  left: 15%;
  animation: moveWave 40s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(3) {
  top: 30%;
  left: 40%;
  animation: moveWave 22s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(4) {
  top: 25%;
  left: 80%;
  animation: moveWave 74s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(5) {
  top: 33%;
  left: 10%;
  animation: moveWave 30s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(6) {
  top: 44%;
  left: 85%;
  animation: moveWave 44s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(7) {
  top: 40%;
  left: 10%;
  animation: moveWave 23s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(8) {
  top: 2.5%;
  left: 63%;
  animation: moveWave 37s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(9) {
  top: 1%;
  left: 4%;
  animation: moveWave 20s linear infinite alternate;
}
.comming-soon__animation:nth-of-type(10) {
  top: 20%;
  left: 5%;
  animation: moveWave 35s linear infinite alternate;
}
@keyframes moveWave {
  10% {
    left: 20%;
  }
  20% {
    left: 40%;
  }
  30% {
    left: 60%;
  }
  40% {
    left: 80%;
  }
  50% {
    left: 90%;
  }
  60% {
    left: 80%;
  }
  70% {
    left: 60%;
  }
  80% {
    left: 40%;
  }
  90% {
    left: 20%;
  }
  100% {
    left: 10%;
  }
}
.comming-soon__lang {
  text-decoration: none;
  background-color: white;
  padding: 20px 14px;
  border-radius: 10px;
  color: var(--main-text-color);
}
@media (max-width: 1024px) {
  .comming-soon {
    padding-top: 30px;
  }
  .comming-soon__title {
    font-size: 46px;
  }
  .comming-soon__desc {
    width: 80%;
  }
}
@media (max-width: 426px) {
  .comming-soon__logo {
    width: 195px;
    height: 32px;
  }
  .comming-soon__sub-title {
    font-size: 30px;
  }
  .comming-soon__title {
    font-size: 36px;
  }
  .comming-soon__desc {
    margin: 0px auto 30px;
  }
  .comming-soon__desc {
    width: 100%;
  }
}
