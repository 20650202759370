.preview-company{
    transform: translateY(-130px);
}


.preview-company__location{
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-bottom: 25px;
    border-radius: 10px;
}
.preview-company__location-cont{
    height:380px;
    position:relative;
}

.preview-company__location-name{
    padding: 20px;
}
.preview-company__location-name p{
    display: flex;
}
.preview-company__location-cat{
    font-family: "RedexBold";
    margin-right: 25px;
}
.preview-company__location-desc{
    
}

.preview-company__details{
    padding: 14px 20px;
    box-shadow: 0px 0px 4px 0px #00000040;
    background-color:white;
    margin-bottom: 20px;
    border-radius: 10px;
}
.preview-company__details p{
    display: flex;
    justify-content: space-between;
}
.preview-company__details--display p{
    justify-content: flex-start;
}
.preview-company__details--display button{
    background-color: transparent;
    border:0;
    outline:0;
    margin-right:25px;
    margin-inline-end: 25px;
}
.preview-company__details-name{
    color: var(--main-text-color);
    margin-right: auto;
    margin-inline-end: auto;
}
.preview-company__details-para{
    margin-bottom: 20px;
    justify-content: flex-start!important;
}
.preview-company__details-para span:first-child{
    max-width:80%
}
p{
    margin: 0;
}

@media (max-width:426px) {
    .preview-company{
        transform: translateY(0px);
    }
    
}