.star-cont__icon{
    width: 16px;
    height: 16px;
    margin-right: 5px;
    margin-inline-end: 5px;
}
body[dir='rtl'] .star-cont{
    justify-content: end;
    direction: ltr;
}
.star-cont__icon--fill path{
    fill: var(--main-color);
}
.star-cont__number{
    font-weight: 300;
}
body[dir='rtl'] .star-cont__icons-cont{
    order: 2;
}
body[dir='rtl'] .star-cont__number{
    order: 1;
}