.add-review__form{
    display: flex;
    flex-direction: column;
    background-color:white;

}
.add-review__input{
    width: 100%;
    height:180px;
    border: 1px solid #E4E6E1;
    padding: 13px 20px;
    margin-bottom: 10px;
    outline: 0;
    border-radius: 10px;
    resize: none;
}


.add-review__submit{
    position: relative;
    padding: 14px 20px;
    color: var(--main-text-color);
    font-family: 'RedexMedium';
    border-radius: 10px;
    border: 0;
    outline: 0;
    transition: var(--transition);
    overflow: hidden;
    background-color: var(--main-color);
    margin-left: auto;
    margin-inline-start: auto;
}
.add-review__submit:before{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    opacity: 0.3;
    transform: translateX(-100%);
    transition: var(--transition);
}
.add-review__submit:hover::before{
    transform: translateX(0%);
}
body[dir='rtl'] .add-review__submit:hover::before{
    transform: translateX(0%);
}
body[dir='rtl'] .add-review__submit::before{
    transform: translateX(100%);
}
