.company{
    padding:120px 0
}
.category__title{
    font-size:36px;
    margin-bottom:32px;
    font-weight: bold;
    color:#325465;
    text-align:center;
    font-family: 'RedexBold', sans-serif!important;
}

.swiper-button-prev {
    background-image: url("/src/assets/icons/leftArrow.svg") !important;
  }
  
  .swiper-button-next {
    background-image: url("/src/assets/icons/rightArrow.svg") !important;
  }