.preview-company__company-description{
    padding: 30px;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-bottom:30px;
    border-radius: 10px;
    background-color:white;

}
.preview-company__description-title{
    color:var(--main-text-color);
    font-size:24px;
    font-family: 'RedexBold';
    margin-bottom: 30px;
}
.preview-company__description-content{
    color: black;
    margin: 0;
    font-family: "RedexMedium";
}