
#about-us{
    margin-top: 120px;
    background: url('/src/assets/imgs/aboutUsBG.png') no-repeat  left
}

.about-us__conetnt{
    text-align: start;
}
.about-us__conetnt-title{
    color: #325465;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 16px;
    font-family: 'RedexBold', sans-serif!important;
}
.about-us__conetnt-desc{
    line-height: 26px;
    color: #000001;
    margin-bottom: 30px;
}
.about-us__read-more{
    
    background: #C4E85F;
    padding: 12px 24px;
    margin: 0 auto;
    border-radius: 10px;
    color: #263238;
    text-decoration: none;
    margin-top: 26px;
    transition: var(--transition);
}
.about-us__read-more:hover{
    text-decoration: none;
    color:inherit;
    opacity: .9
}
@media (max-width:768px){
    .about-us__img{
        margin-bottom: 80px;
    }
    .about-us__conetnt-desc{
        margin-bottom: 0;
    }
}
#about-us_page{
    margin: 120px 0 ;
    background: url('/src/assets/imgs/aboutUsBG.png') no-repeat  left
}