.categories__show-all{
    background: #C4E85F;
    padding: 12px 24px;
    margin: auto;
    border-radius: 10px;
    color: #263238;
    text-decoration: none;
    margin-top: 26px;
    transition: var(--transition);
}
.categories__show-all:hover{
    text-decoration: none;
    color:inherit;
    opacity: .9
}