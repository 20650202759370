.preview-company__company-review{
    padding: 30px;
    box-shadow: 0px 0px 4px 0px #00000040;
    margin-bottom: 30px;
    border-radius: 10px;
    background-color:white;
}
.preview-company__review-comment{
    border-bottom:  1px solid #F1F5EB;
}
.preview-company__review-title{
    color: var(--main-text-color);
    font-size: 24px;
    font-family: 'RedexBold';
    padding-bottom: 30px;
    border-bottom:  1px solid #F1F5EB;
}
.preview-company__comment-info-cont{
    display: flex;
    align-items:center;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.preview-company__comment-name{
    font-size: 24px;
}
.preview-company__comment{
    color:#777777;
    line-height: 24px
}
@media(max-width:426px){
    .preview-company__comment-info-cont{
        flex-direction: column;
        align-items: initial;
        margin-bottom: 0;
    }
    .preview-company__comment{
        margin-top: 10px;
        padding-bottom: 10px;
    }
}